@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

html, body, #root {
  background: $dark !important;
  height: 100%;
}

main {
  margin: -55px auto 0 auto;
  background: $dark;
  height: 100%;
  display: flex;
  max-width: 800px;
}

.container {
  margin-top: auto;
  margin-bottom: auto;
}

.header-logo {
  display: flex;
  height: 55px;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
}

.header-logo img {
  max-width: 800px;
  margin: 0 auto;
}

.bg-onion {
  background-color: #7D4698;
}

$custom-colors: (
        "onion": #7D4698
);

.btn {
  padding: 3px;
  margin: 3px;
}

.form-control {
  margin: 3px;
}

#urlmirror {
  background-color:#aaaaaa;
}

$theme-colors: map-merge($theme-colors, $custom-colors);

@import "~bootstrap/scss/bootstrap";
